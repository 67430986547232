import styled, { css } from "styled-components"
import { LIGHT_THEME } from "../../theme"
import createCssVariables from "../../utils/createCssVariables"

const Container = styled.div`
  ${props => (props.light ? createCssVariables(LIGHT_THEME) : ``)}

  ${props =>
    props.compact
      ? css`
          max-width: 1024px;
          width: calc(100% - 4.8rem);
          margin: 0 auto;
        `
      : ``}

      ${props =>
    props.bleed === "right"
      ? css`
          padding: 0 0 0 max(2.4rem, 50vw - 1024px / 2);
        `
      : props.bleed === "left"
      ? css`
          padding: 0 max(2.4rem, 50vw - 1024px / 2) 0 0;
        `
      : props.bleed === "both"
      ? css`
          padding: 0 max(2.4rem, 50vw - 1024px / 2);
        `
      : ``}

  background-color: var(--main);
  color: var(--text);
`

export default Container
