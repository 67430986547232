import { createGlobalStyle } from "styled-components"
import createCssVariables from "../../utils/createCssVariables"

const GlobalStyles = createGlobalStyle`
  :root {
    ${props => createCssVariables(props.theme)}
  }

  html {
    font-size: 10px;
  }

  body {
    background-color: var(--main);
    color: var(--text);
    font-family: 'aktiv-grotesk-extended', sans-serif;
    font-size: 1.6rem;
    overflow-y: ${props => (props.isMenuOpen ? "hidden" : "auto")};
  }
`

export default GlobalStyles
