import React from "react"
import { Helmet } from "react-helmet"
import canPreview from "../../utils/canPreview"

function SEO({
  description = "",
  lang = "en",
  meta = canPreview()
    ? [
        {
          name: "robots",
          content: "noindex",
        },
      ]
    : [],
  title,
}) {
  const metaDescription =
    "PNOĒ scans your breath and analyses your metabolism with clinical accuracy to give you the most personalized nutrition, activity, and recovery insights science can offer."
  const defaultTitle = "PNOĒ"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
