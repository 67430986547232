const FONT_SIZES = {
  lg: 32,
  base: 16,
  sm: 12,
}

const MOBILE_FONT_SIZES = {
  lg: 24,
  base: 14,
  sm: 10,
}

const MOBILE_LINE_HEIGHTS = {
  lg: 1.33,
  base: 1.14,
  sm: 1.08,
}

const LINE_HEIGHTS = {
  lg: 1.25,
  base: 1.25,
  sm: 1.08,
}

export const DARK_THEME = {
  main: "#191919",
  text: "#ebeaea",
  error: "#db005b",
  colorShadowOne: "#242424",
  colorShadowTwo: "#09090a",
  fontSize: FONT_SIZES,
  lineHeight: LINE_HEIGHTS,
  mobileFontSize: MOBILE_FONT_SIZES,
  mobileLineHeight: MOBILE_LINE_HEIGHTS,
}

export const LIGHT_THEME = {
  main: "#ebeaea",
  text: "#191919",
  error: "#db005b",
  colorShadowOne: "#ffffff",
  colorShadowTwo: "rgba(25, 25, 25, 0.12)",
  fontSize: FONT_SIZES,
  lineHeight: LINE_HEIGHTS,
  mobileFontSize: MOBILE_FONT_SIZES,
  mobileLineHeight: MOBILE_LINE_HEIGHTS,
}
