import { isObject, kebabCase } from "lodash"

export default function createCssVariables(theme) {
  return Object.entries(theme)
    .filter(tuple => !isObject(tuple[1]))
    .map(([key, value]) => {
      return `--${kebabCase(key).replace(/-(\d+)/g, "$1")}: ${String(value)};`
    })
    .join("\n")
}
